import { VStack, Image, Text } from '@chakra-ui/react';

const PageNotFound = () => (
  <VStack minH='100vh' justifyContent='start' alignItems='center' textAlign='center' pt='50px'>
    <Image src='/icons/head-black-transparentbg.png' w='250px' h='250px' p='20px'/>
    <Text fontWeight='700' fontSize='100px' lineHeight='0.75'>404</Text>
    <Text fontWeight='500' fontSize='24px'>Page not found</Text>
  </VStack>
)

export default PageNotFound;