import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = ({
  fallbackPath,
  children,
}) => {
  const user = useAuth();
  return user
    ? children
    : <Navigate to={fallbackPath} state={{ redirect: true }}/>
}

export default PrivateRoute;