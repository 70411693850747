import { createContext, useContext, useEffect, useState } from "react";
import { identifyUser } from "../monitor/mixpanel";
import { onAuthChange } from "./auth";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => onAuthChange((user) => {
    setUser(user);
    if (user && user.uid && user.displayName && user.email) {
      identifyUser(user.uid, user.displayName, user.email)
    }
  }, console.log), []);

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const { user } = useContext(AuthContext);
  return user;
}

export {
  AuthProvider,
  useAuth
};