import { trackEvent } from "./mixpanel";

export const LOG_EVENT = {
  SIGN_UP: 'sign_up',
  SIGN_IN: 'sign_in',
  SIGN_OUT: 'sign_out',
  PURCHASE: 'purchase',
  SELECT_PROBLEM: 'select_problem',
  SELECT_TAG: 'select_tag',
  START_REVIEW: 'start_review',
  NEXT_PROBLEM: 'next_problem',
  CLOSE_REVIEW: 'close_review',
  COMPLETE_PROBLEM: 'complete_problem',
  ADD_CUSTOM_PROBLEM: 'add_custom_problem',
  EDIT_PROBLEM_TAG: 'edit_problem_tag',
  FINISH_REVIEW: 'finish_review',
  LATENCY: 'latency'
};

export const PROBLEM_CLOSE_LOCATION = {
  IM_DONE: 'im_done',
  CLOSE_BUTTON: 'close_button',
}

export const SIGN_IN_METHOD = {
  EMAIL: 'email',
  PROVIDER: 'provider'
}

const VALID_SIGN_UP_SOURCES = [
  'blind',
  'reddit'
];

// ------------------------------
// AUTH
// ------------------------------

export const logSignIn = (method) => trackEvent(LOG_EVENT.SIGN_IN, { method });

export const logSignUp = (method, source) => {
  if (source && VALID_SIGN_UP_SOURCES.includes(source)) {
    trackEvent(LOG_EVENT.SIGN_UP, { method, source });
  } else {
    trackEvent(LOG_EVENT.SIGN_UP, { method, source: null });
  }
}

export const logSignOut = () => trackEvent(LOG_EVENT.SIGN_OUT);

// ------------------------------
// PROBLEM
// ------------------------------

export const logSelectProblem = (problem_name) => trackEvent(LOG_EVENT.SELECT_PROBLEM, { problem_name });

export const logCompleteProblem = (problem_name, score, was_selected) => trackEvent(LOG_EVENT.COMPLETE_PROBLEM, { problem_name, score, was_selected });

export const logAddCustomProblem = (problem_name, url, tag) => trackEvent(LOG_EVENT.ADD_CUSTOM_PROBLEM, { problem_name, url, tag });

export const logEditProblemTag = (problem_name, original_tag, new_tag) => trackEvent(LOG_EVENT.EDIT_PROBLEM_TAG, { problem_name, original_tag, new_tag });

export const logSelectTag = (tag) => trackEvent(LOG_EVENT.SELECT_TAG, { tag });

// ------------------------------
// DAILY REVIEW
// ------------------------------

export const logStartDailyReview = (tag) => trackEvent(LOG_EVENT.START_REVIEW, { tag });

export const logAdvanceDailyReview = () => trackEvent(LOG_EVENT.NEXT_PROBLEM);

export const logEndDailyReview = (close_location) => trackEvent(LOG_EVENT.CLOSE_REVIEW, { close_location });

export const logFinishReview = () => trackEvent(LOG_EVENT.FINISH_REVIEW);

// ------------------------------
// PERFORMANCE
// ------------------------------

export const logAPILatency = (api_name, latency_ms) => trackEvent(LOG_EVENT.LATENCY, { api_name, latency_ms });