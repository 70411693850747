const isLocalHost = () => window.location.hostname === 'localhost';

const isProduction = () => window.location.hostname === 'codellama.dev';

const getArrAvg = (arr) => Math.round(arr.length === 0 ? 0 : arr.reduce((a, b) => a + b, 0) / arr.length * 10) / 10;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const getProblemURL = (problemName) => `https://leetcode.com/problems/${problemName}`;

const getDateStr = (date) => {
  const dateObj = date || new Date();
  return dateObj.toISOString();
}

const getLocalDateFromStr = (dateStr) => {
  const dateObj = new Date(Date.parse(dateStr));
  return dateObj.toLocaleDateString();
}

const getDatePlusDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
}

const getProblemDueDate = (problemData) => getDatePlusDays(new Date(Date.parse(problemData.lastReviewDate)), problemData.sm2value.i);

const isProblemDue = (problemData) => (new Date()) >= getProblemDueDate(problemData).setHours(0, 0, 0, 0);

const getRandomElementFromList = (list) => list[Math.round(Math.random() * list.length)];

export {
  isLocalHost,
  isProduction,
  getArrAvg,
  sleep,
  getProblemURL,
  getDateStr,
  getDatePlusDays,
  getProblemDueDate,
  isProblemDue,
  getRandomElementFromList,
  getLocalDateFromStr
}