export const siteName = 'code llama';

export const signUp = {
  signUpPhrase: [
    'Your next job might be just a week of practice away...',
    'What are you waiting for?'
  ],
  passwordsDoNotMatch: 'Passwords do not match',
  signUpError: 'Invalid username or password',
  forgotPasswordError: 'Unable to send password reset email'
}

export const pages = {
  signUp: {
    signUpPhrase: [
      'Your next job might be just a week of practice away...',
      'What are you waiting for?'
    ],
    passwordsDoNotMatch: 'Passwords do not match',
    signUpError: 'Invalid email or password',
    forgotPasswordError: 'Unable to send password reset email'
  },
  home: {
    title: 'Problem List',
    subhead: 'The master list for all your problems. Click "Start Daily Review" to go through all problems ready for review, or click on any individual problem to review it by itself. We recommend clearing your editor after each problem so you\'re forced to recall from scratch the next time.',
  },
  scoreModal: {
    description: 'Give yourself a score from 0-5, (0 being a complete blackout, 5 meaning got it immediately). We use this score to adjust when you\'ll review this problem next.',
    motivation: [
      'You got this!',
      'Stay focused!',
      'Getting ahead of the competition one problem at a time.',
      'Let\'s get it done!'
    ]
  },
  about: {
    landingSlogan: 'Don\'t settle for less. Build your interview skills and take your career to the next level.',
    aboutHeader: 'About Code Llama',
    aboutBody: `
      Code Llama is the one-stop-shop for advancing your career (and your salary)
      as a Software Engineer to the next level. Our site is based around a learning system called
      spaced repetition (or distributed practice), in which problems are revisited at an increasing
      interval as you continue to progress. In other words, the more you get a problem right, the less
      frequently you’ll review it and vice-versa. Spaced repetition has been shown by several studies as
      being one of the most effective mechanisms for learning and building long-term memory [1,2,3].
      Our system uses our own variation of a heavily researched spaced repetition algorithm called SuperMemo-2 (SM2) [4]
      which tracks a handful of signals from your performance to determine the optimal review interval
      for a given problem. Couple this with our curated collection of learning resources and you are well on
      your way to acing your next phone screen or onsite interview.
    `,
    scoringHeader: 'But how does it know if I got the problem right?',
    scoringBody1: `
      Since we don’t want to reinvent the wheel here, Code Llama simply redirects you to LeetCode for each problem
      you review. In order to track your progress, when you are done with the problem and return to the site you’ll
      be prompted to give yourself a score from 0 to 5. This is one of the key factors in determining when you should
      review the problem next, so make sure you’re being honest with yourself or else you’ll miss out on the benefits
      of spaced repetition entirely. When scoring, you can use this as a general reference:
    `,
    scoringBullets: [
      '0: "Total blackout", complete failure to recall the information.',
      '1: Incorrect response, but upon seeing the correct answer it felt familiar.',
      '2: Incorrect response, but upon seeing the correct answer it seemed easy to remember.',
      '3: Correct response, but required significant effort to recall.',
      '4: Correct response, after some hesitation.',
      '5: Correct response with perfect recall.'
    ],
    scoringBody2: `
      And that’s it! We’ll take care of the math and heavy-lifting behind the scenes and update your problem review
      schedule each time you complete one.
    `,
    noteHeader: 'Note',
    noteBody1: `
      You may find that it takes you a while to get through the initial problem list for the first time– that’s ok!
      As you continue to review problems more frequently, if you keep getting them right (i.e. scoring a 3 or higher)
      then the problems will be due for review less and less frequently, giving you plenty of opportunities to progress
      through the list. Problems should gradually get mixed in to your daily review as you continue to improve. And besides,
      no point in moving on from a problem before you’ve really got it down. This is why we generally recommend using the
      daily review tool we provide rather than selecting problems individually, as you may feel ready to move on before your
      scores agree. Always go with the data! You can also take advantage of the problem tag drop down (next to the Daily Review button)
      to review a smaller selection of problems at a time, or practice a specific category you're looking to improve in.
    `,
    noteBody2: `
      Remember, the goal here is to form a robust foundation for long term recall, so be patient and trust the process, giving
      yourself time to slightly forget a problem is a crucial element of the spaced repetition system and largely responsible
      for its overall efficacy. This can feel frustrating at times, but we’re confident that if you stick with it you’ll be
      pleased with the lasting results.
    `
  },
  cookies: {
    message: 'Code Llama uses cookies to persist your login state and basic user settings (like the number of problems listed per page) across sessions.',
    buttonText: 'Continue to Site'
  }
}