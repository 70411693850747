import {
  HStack,
  Text,
  Link as TextLink,
  Image,
  useMediaQuery
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { signOutUser } from '../auth/auth';
import { useAuth } from '../auth/AuthProvider';
import * as text from '../constants/text';

const PAGE_LINKS = {
  about: {
    label: 'About',
    path: '/about'
  },
  home: {
    label: 'Problems',
    path: '/problems'
  }
}

const Header = () => {
  const user = useAuth();
  const location = useLocation();

  const [isMobile] = useMediaQuery('(max-width: 615px)');

  const handleSignOut = () => {
    signOutUser();
  }

  return (
    <HStack boxShadow='0px 0px 10px #cccccc' justifyContent='space-between' px={['20px', '20px', '10vw']} py='10px' flexWrap='wrap'>
      <HStack>
        <HStack mr={['5px', '20px']}>
          <Image src='/icons/black-transparentbg.png' alt='codellama-logo' w='30px' h='30px'/>
          {
            isMobile
            ? null
            : <Link to='/' ml='5' pb='1'>
                <Text fontSize='20px' maxW={['70px', '300px']} fontWeight={700} lineHeight={1}>{text.siteName}</Text>
              </Link>
          }
        </HStack>
        <HStack gap={['5px', '20px']}>
        {
          Object.values(PAGE_LINKS).map(({ label, path }) => <Link
            to={path}
            key={label}
          >
              <Text fontWeight={location.pathname === path ? 'bold' : 'medium'} color={location.pathname === path ? 'black' : 'blackAlpha.600'}>{label}</Text>
            </Link>
          )
        }
        </HStack>
      </HStack>
      {
        user
        ? <HStack>
            {
              isMobile
              ? null
              : <Text fontStyle='italic' mr='5'>{`Welcome${user.displayName ? `, ${user.displayName}` : '!'}`}</Text>
            }
            <TextLink fontWeight='bold' onClick={handleSignOut}>Sign Out</TextLink>
          </HStack>
        : <HStack>
            <Link to='/signin'><Text fontWeight='bold'>Sign In</Text></Link>
          </HStack>
      }
    </HStack>
  )
};

export default Header;