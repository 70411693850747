import {
  Badge,
  Box,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';

const BetaHeader = () => (
  <HStack justifyContent='space-between' alignItems='center' height='50px' bg='slate' px='25px'>
    <Box flex={['0 1 0', '0 1 0', '1 1 auto']} />
    <Text flex='1 0 0' color='white' fontWeight='700' textAlign={['left', 'left', 'center']}>FREE BETA</Text>
    <HStack flex='1 0 0' justifyContent='flex-end' spacing={3}>
      <Link flexShrink='0'  color='white' href='https://docs.google.com/forms/d/e/1FAIpQLSfq7dLK4VlZoRTw-x2Py0rl56HzgtGUjoVSu89qCBlgPNS_cA/viewform?usp=sf_link' textAlign='right'>User Feedback Survey</Link>
      <Badge colorScheme='green'>NEW</Badge>
    </HStack>
  </HStack>
);

export default BetaHeader;