import { lazy, Suspense } from 'react';
import {
  ChakraProvider,
  Flex,
  Spinner,
  extendTheme
} from '@chakra-ui/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import PrivateRoute from './auth/PrivateRoute';
import { AuthProvider } from './auth/AuthProvider';
import CookiePermission from './components/CookiePermission';
import Footer from './components/Footer';
import BetaHeader from './components/BetaHeader';
import PageNotFound from './pages/PageNotFound';

const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ProblemsPage = lazy(() => import('./pages/ProblemsPage'));

const theme = extendTheme({
  colors: {
    slate: '#303030'
  }
});

const CenteredSpinner = () => <Flex justifyContent='center' alignItems='center' h='100vh'><Spinner /></Flex>
const Lazy = ({ children }) => <Suspense fallback={<CenteredSpinner />}>{children}</Suspense>;

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <BetaHeader />
        <Header />
        <Routes>
          <Route path='/signup'>
            <Route path=':source' element={<Lazy><SignUpPage isSignUp={true}/></Lazy>} />
            <Route path='' element={<Lazy><SignUpPage isSignUp={true}/></Lazy>} />
          </Route>
          <Route path='/signin' element={<Lazy><SignUpPage isSignUp={false}/></Lazy>} />
          <Route path='/about' element={<Lazy><AboutPage /></Lazy>} />
          <Route path='/problems' element={
            <PrivateRoute fallbackPath='/signin'>
              <Lazy><ProblemsPage /></Lazy>
            </PrivateRoute>
          }/>
          <Route path='/' element={
            <PrivateRoute fallbackPath='/about'>
              <Navigate to='/problems'/>
            </PrivateRoute>
          }/>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer />
        <CookiePermission />
      </AuthProvider>
    </ChakraProvider>
  )
};

export default App;
