import {
  Text,
  VStack,
  HStack,
  Image,
  useMediaQuery,
  Icon,
  Link
} from '@chakra-ui/react';
import {
  FaEnvelope,
  FaPhone,
  FaTwitter,
  FaInstagram
} from 'react-icons/fa';

const Footer = () => {
  const [isMobile] = useMediaQuery('(max-width: 750px)')

  const Stack = ({ children, ...props }) => (
    isMobile
    ? <VStack px='50px' py='20px' justifyContent='flex-start' {...props}>{children}</VStack>
    : <HStack py='50px' justifyContent='center' {...props}>{children}</HStack>
  )

  return (
    <Stack
      alignItems='start'
      bg='slate'
      color='whiteAlpha.700'
      gap='10px'
      w='100vw'
    >
      <VStack justifyContent='flex-start' h='100%'>
        <Image src='/icons/white-transparentbg.png' alt='codellama-logo' w='50px' h='50px' />
      </VStack>
      <VStack justifyContent='flex-start' alignItems='start' h='100%' minW='250px' lineHeight={1.15}>
        <Text fontWeight={700} fontSize='18px' color='whiteAlpha.900'>CONTACT</Text>
        <HStack>
          <Icon as={FaEnvelope} />
          <Link href='mailto:codellama.dev@gmail.com' fontSize='16px'>codellama.dev@gmail.com</Link>
        </HStack>
        <HStack>
          <Icon as={FaPhone} />
          <Link href='tel:2135346422' fontSize='16px'>(213) 534-6422</Link>
        </HStack>
      </VStack>
      <VStack justifyContent='flex-start' alignItems='start' h='100%' textAlign='left' minW='250px' lineHeight={1.15}>
        <Text fontWeight={700} fontSize='18px' color='whiteAlpha.900'>SOCIAL</Text>
        <HStack>
          <Link href='https://twitter.com/codellama_dev' aria-label='twitter'><Icon as={FaTwitter} boxSize={8}/></Link>
          <Link href='https://www.instagram.com/codellama.dev/' aria-label='instagram'><Icon as={FaInstagram} boxSize={8}/></Link>
        </HStack>
      </VStack>
      <Text>Copyright © 2022 CodeLlama</Text>
    </Stack>
  )
};

export default Footer;