import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { isLocalHost, isProduction } from './util/util';

const PROJECT_CONFIG = {
  DEV: {
    apiKey: "AIzaSyDF__dkeUv7vAPADhhPRx2vF9sezdtIx8w",
    authDomain: "code-llama-dev.firebaseapp.com",
    projectId: "code-llama-dev",
    storageBucket: "code-llama-dev.appspot.com",
    messagingSenderId: "380458665094",
    appId: "1:380458665094:web:fae0bc1710629e170abd07"
  },
  PROD: {
    apiKey: "AIzaSyBWLylfHktFe4GY-aflxTeeaFkzWiJ1SyU",
    authDomain: "codellama.dev",
    projectId: "codellama-f95c6",
    storageBucket: "codellama-f95c6.appspot.com",
    messagingSenderId: "94449106914",
    appId: "1:94449106914:web:9b9e64a02a9f41bba0c3bb",
    measurementId: "G-LWYRQE3THT"
  }
};

initializeApp(isProduction() ? PROJECT_CONFIG.PROD : PROJECT_CONFIG.DEV);

const auth = getAuth();
if (isLocalHost()) {
  connectAuthEmulator(auth, 'http://localhost:5003');
}

export { auth };