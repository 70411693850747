import { auth } from '../Firebase';
import {
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import { logSignOut } from '../monitor/log';
import { identifyUser } from '../monitor/mixpanel';

export const SIGN_IN_PROVIDER = {
  GOOGLE: new GoogleAuthProvider(),
  GITHUB: new GithubAuthProvider()
}

export const onAuthChange = async (onSuccess, onError) => {
  onAuthStateChanged(auth, onSuccess, onError);
}

export const signUpUser = async (firstName, lastName, email, password) => {
  await setPersistence(auth, browserLocalPersistence);
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await updateProfile(userCredential.user, { displayName: `${firstName} ${lastName}`});
  identifyUser(userCredential.user.uid, `${firstName} ${lastName}`, email);
}

export const signInUser = async (email, password) => {
  await setPersistence(auth, browserLocalPersistence);
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  identifyUser(user.uid, user.displayName, user.email);
}

export const signInUserWithPopup = async (provider) => {
  const { user } = await signInWithPopup(auth, provider);
  identifyUser(user.uid, user.displayName, user.email);
}

export const signOutUser = async () => {
  await signOut(auth);
  logSignOut();
}

export const forgotPassword = async (email) => {
  await sendPasswordResetEmail(auth, email);
}

export const getAuthToken = async () => {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken();
  }
  return null;
}