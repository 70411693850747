import {
  HStack,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import BlackButton from './BlackButton';
import { pages } from '../constants/text';

const CookiePermission = () => {
  const ACCEPT_COOKIES_KEY = 'ACCEPTS_COOKIES';

  const [acceptsCookies, setAcceptsCookies] = useState(false);

  useEffect(() => {
    setAcceptsCookies(getCookiesState());
  }, [])

  const getCookiesState = () => JSON.parse(window.localStorage.getItem(ACCEPT_COOKIES_KEY) || false);

  const handleClick = () => {
    setAcceptsCookies(true);
    window.localStorage.setItem(ACCEPT_COOKIES_KEY, JSON.stringify(true))
  };

  return (
    acceptsCookies
    ? null
    : <HStack justifyContent='center' flexWrap='wrap' rowGap='20px' w='100vw' pos='fixed' bottom='0' bg='white' px='20px' py='8' boxShadow='0px 0px 15px #efefef'>
        <Text maxW='600px'>{pages.cookies.message}</Text>
        <BlackButton minW='150px' onClick={handleClick}>{pages.cookies.buttonText}</BlackButton>
      </HStack>
  )
};

export default CookiePermission