import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROJ_TOKEN = 'ee0bd72e86f8e6e4496f5fcb4b14e2a4';
mixpanel.init(MIXPANEL_PROJ_TOKEN);

export const identifyUser = (userId, name, email) => {
  mixpanel.identify(userId);
  mixpanel.people.set({
    $name: name,
    $email: email,
    $distinct_id: userId
  });
}

export const trackEvent = (event, data) => {
  mixpanel.track(event, data);
}